<template>
  <div>
    <el-dialog title="新增出库单" :close-on-click-modal="false" width="1300px" :visible.sync="addVisible"
               :before-close="closeEvent">
      <div class="order-table-wrapper">
        <div style="margin-bottom: 20px">
          <div class="handle-box">
            <span class="v-form-label">出库方式:</span>
            <el-select size="small" clearable v-model="otStockType" style="width: 265px;" placeholder="请选择">
              <el-option
                v-for="item in stockTypeList"
                :key="item.dictValue"
                :label="item.name"
                :value="item.dictValue">
              </el-option>
            </el-select>
            <div v-if="otStockType=='4'">
              <span class="v-form-label">调出门店:</span>
              <el-select size="small" clearable v-model="inDepartmentId" style="width: 265px;" placeholder="请选择">
                <el-option
                  v-for="item in departmens"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div v-if="otStockType=='5'||otStockType=='7'||otStockType=='8'||otStockType=='9'">
              <span class="v-form-label">员工:</span>
              <el-select size="small" clearable v-model="employeeId" style="width: 265px;" placeholder="请选择">
                <el-option
                  v-for="item in departmens"
                  :key="item.id"
                  :label="item.departmentName"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <el-row type="flex" justify="end" style="margin-left: 30px">
              <el-button size="small" class="e-button" type="primary" @click="choiceSku()">+ 选择商品
              </el-button>
            </el-row>
          </div>
        </div>
        <div class="e-card">
          <div class="e-card-body" style="padding-top:10px">
            <div class="e-table-wrapper">
              <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
                <thead>
                <tr>
                  <th width="130" class="th-l">商品编号</th>
                  <th width="130" class="th-l">商品名称</th>
                  <th width="130" class="th-l">规格</th>
                  <th width="130" class="th-l">单位</th>
                  <th width="130" class="th-l">供应商</th>
                  <th width="130" class="th-l">当前库存</th>
                  <th width="130" class="th-l">数量</th>
                  <th width="130" class="th-l">单价</th>
                  <th width="130" class="th-l">小计</th>
                  <th width="200" class="th-l">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row,index) in list" :key="row.id">
                  <td class="td-l"><span>{{row.skuNo}}</span></td>
                  <td class="td-l"><span>{{row.skuName}}</span></td>
                  <td class="td-l">{{row.skuSpecs}}</td>
                  <td class="td-l">{{unitTypeMap[row.unitType]}}</td>
                  <td class="td-l">{{row.manufactorName}}</td>
                  <td class="td-l">
                    {{row.initInventory}}
                  </td>
                  <td class="td-l">
                    <el-input size="small" v-model="row.number" style="width: 80px"></el-input>
                  </td>
                  <td class="td-l">
                    {{row.retailPrice}}
                    <!--<el-input  size="small"  v-model="row.retailPrice" style="width: 80px"></el-input>-->
                  </td>
                  <td class="td-l">
                  </td>
                  <td class="td-l">
                    <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px">
          <el-button size="small" @click="closeEvent">取 消</el-button>
          <el-button size="small" type="primary" @click="saveInStock">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="选择商品" v-if="skuVisible" width="1000px" :visible.sync="skuVisible"
               :before-close="skuEvent">
      <!--style="height: 300px;border-bottom: #8c939d solid 1px;"-->
      <div style="height: 300px;">
        <el-row>
          <el-col :span="10">
            <el-table
              :data="categoryList"
              ref="singleTable"
              highlight-current-row
              @current-change="getSkuList"
              style="width: 300px">
              <el-table-column
                prop="categoryName"
                label="商品分类">
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="14">
            <span class="v-form-label"
                  style="width: 560px;height: 30px;font-size: 15px;padding-top: 13px;border-bottom: #EBEEF5 solid 1px;text-align:left ">商品列表</span>
            <div class="grid-content bg-purple-light;">
              <el-table
                :data="skuList"
                ref="singleTable"
                @current-change="addSku"
                style="width: 100%">
                <el-table-column
                  align="center"
                  prop="skuNo"
                  label="商品编号">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="skuName"
                  label="商品名称">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="skuSpecs"
                  label="规格">
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="unitType"
                  label="单位">
                  <template slot-scope="scope">
                    {{unitTypeMap[scope.row.unitType]}}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </div>
      <!--<div style="margin-top: 18px">-->
      <!--<span class="v-form-label"-->
      <!--style="width: 560px;height: 30px;font-size: 15px;padding-top: 13px;border-bottom: #EBEEF5 solid 1px;text-align:left ">选择产品</span>-->
      <!--<div class="grid-content bg-purple-light;">-->
      <!--<el-table-->
      <!--:data="skus"-->
      <!--ref="singleTable"-->
      <!--@current-change="delSku"-->
      <!--style="width: 100%">-->
      <!--<el-table-column-->
      <!--align="center"-->
      <!--prop="skuNo"-->
      <!--label="商品编号">-->
      <!--</el-table-column>-->
      <!--<el-table-column-->
      <!--align="center"-->
      <!--prop="skuName"-->
      <!--label="商品名称">-->
      <!--</el-table-column>-->
      <!--<el-table-column-->
      <!--align="center"-->
      <!--prop="skuSpecs"-->
      <!--label="规格">-->
      <!--</el-table-column>-->
      <!--<el-table-column-->
      <!--align="center"-->
      <!--prop="unitType"-->
      <!--label="单位">-->
      <!--<template slot-scope="scope">-->
      <!--{{unitTypeMap[scope.row.unitType]}}-->
      <!--</template>-->
      <!--</el-table-column>-->
      <!--</el-table>-->
      <!--</div>-->
      <!--<div style="margin-top: 20px">-->
      <!--<el-button size="small" type="primary" @click="ok">选好了</el-button>-->
      <!--</div>-->
      <!--</div>-->
    </el-dialog>

  </div>
</template>
<script>

  export default {
    data() {
      return {
        skuVisible: false,

        remarks: '',
        otStockType: '',
        inDepartmentId: '',
        employeeId: '',
        list: [],

        stockTypeList:[],

        employees: [],

        departmens: [],
        categoryList: [],
        skuList: [],
        skus: [],
        item: {},
        unitTypeMap: {},
      }
    },
    props: {
      addVisible: Boolean
    },

    created() {
      this.getUnitTypeList()
      this.getCategoryList()
      this.getDepartmens()
      this.getEmployees()
      this.getOtStockType()
    },

    methods: {
      async getOtStockType(){
        let res = await this.$get('/admin/getDictDetailList/OT_STOCK_TYPE/' + Math.random());
        this.stockTypeList = res;
      },
      async getDepartmens() {
        let res = await this.$get("/admin/getDepartmens")
        this.departmens = res
      },
      async getEmployees() {
        let res = await this.$get("/admin/getEmployeeList")
        this.employees = res.list
      },

      addSku(val) {
        this.list.push(val)
        this.skuVisible = false
      },

      // delSku(val) {
      //   this.skuList.splice(val.i, 0, val);
      //   var index = this.skus.indexOf(val)
      //   this.skus.splice(index, 1)
      // },

      async getSkuList(val) {
        this.skuList = []
        let data = {
          categoryId: val.id
        }
        let res = await this.$get("/admin/getSkus", data)
        this.setList(res.skuList)
      },

      setList(skuList) {
        if (skuList.length > 0) {
          let arr = [...skuList]
          let list = []
          if (this.list[0] != null) {
            list = this.list
            if (list.length > 0 && skuList[0] != null) {
              for (let i = 0; i < skuList.length; i++) {
                for (let j = 0; j < list.length; j++) {
                  if (list[j].id == skuList[i].id) {
                    arr.splice(i, 1)
                  }
                }
              }
            }
          }
          this.skuList = arr
        }
      },

      choiceSku() {
        this.skuList = []
        this.skuVisible = true
      },

      async getCategoryList() {
        let data = {
          categoryType: 1
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },

      async getUnitTypeList() {
        let res = await this.$get('/admin/getDictDetailList/UNIT_TYPE/' + Math.random());
        res.forEach(item => {
          this.unitTypeMap[item.dictValue] = item.name
        })
      },

      removeEvent(index) {
        this.list.splice(index, 1)
      },

      closeEvent() {
        this.$emit('closeInsert')
      },

      skuEvent() {
        this.skuVisible = false
      },


      async saveInStock() {

        if (!this.otStockType) {
          this.$message.error("请选择入库方式")
          return;
        }

        if (!this.list.length > 0) {
          this.$message.error("请选择商品")
          return;
        }
        let skuList = []
        this.list.forEach(item => {
          let sku = {
            skuId: item.id,
            number: item.number,
            unitPrice: item.retailPrice
          }
          skuList.push(sku)
        })

        if (!this.otStockType == '4') {
          this.inDepartmentId == ''
        }
        if (!this.otStockType == '5' || !this.otStockType == '7' || !this.otStockType == '8' || !this.otStockType == '9') {
          this.employeeId == ''
        }

        let data = {
          otStockType: this.otStockType,
          inDepartmentId: this.inDepartmentId,
          employeeId: this.employeeId,
          remarks: this.remarks,
          summary: this.list[0].skuName,
          skuListStr: JSON.stringify(skuList)
        }
        let res = await this.$post("/admin/save_wmOtStock", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$emit('addSuccess')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
</style>
