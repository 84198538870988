<template>
  <div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">

          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">单号:</span>
              <el-input placeholder="单号" size="small" style="width:265px" v-model="otNo" clearable
                        class="handle-input mr10"></el-input>
              <span class="v-form-label">单据类型:</span>
              <el-select size="small" clearable v-model="otStockType" style="width: 265px;" placeholder="请选择">
                <el-option
                  v-for="item in stockTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
              <span class="v-form-label">状态:</span>
              <el-select size="small" clearable v-model="auditStatus" style="width: 265px;" placeholder="请选择">
                <el-option
                  v-for="item in auditStatusList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-row type="flex" justify="end" style="margin-left: 30px">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
                <el-button size="small" class="e-button" type="success" @click="addEvent()">商品出库
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">门店</th>
                <th width="130" class="th-l">单号</th>
                <th width="130" class="th-l">单据类型</th>
                <th width="130" class="th-l">调入门店</th>
                <th width="130" class="th-l">单据摘要</th>
                <th width="130" class="th-l">时间</th>
                <th width="130" class="th-l">单据金额</th>
                <th width="130" class="th-l">经办人</th>
                <th width="100" class="th-l">状态</th>
                <th width="100" class="th-l">审核人</th>
                <th width="130" class="th-l">审核时间</th>
                <th width="110" class="th-l">冻结状态</th>
                <th width="130" class="th-l">备注</th>
                <th width="350" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>

              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.storeName}}</span></td>
                <td class="td-l"><span>{{row.otNo}}</span></td>
                <td class="td-l">
                  {{stockTypeMap[row.otStockType]}}
                </td>
                <td class="td-l"><span>{{row.otStoreName}}</span></td>
                <td class="td-l">{{row.summary}}</td>
                <td class="td-l">
                  {{row.createTime}}
                </td>
                <td class="td-l">
                  {{row.billAmount}}
                </td>
                <td class="td-l">
                  {{row.agent}}
                </td>
                <td class="td-l">
                  <span style="color: red" v-if="row.auditStatus==0">未审核</span>
                  <span style="color: #1abc9c" v-if="row.auditStatus==1">审核</span>
                </td>
                <td class="td-l"><span>{{row.examineName}}</span></td>
                <td class="td-l"><span>{{row.auditTime}}</span></td>
                <td class="td-l">
                  <span v-if="row.frozenStatus==0">未冻结</span>
                  <span v-if="row.frozenStatus==1">冻结</span>
                </td>
                <td class="td-l"><span>{{row.remarks}}</span></td>
                <td class="td-l" style="width: 340px">
                  <el-button type="text" icon="el-icon-edit" @click="toItemList(index)">明细</el-button>
                  <el-button type="text" icon="el-icon-delete" v-if="row.auditStatus==0" @click="audit(index,'1')">
                    审核
                  </el-button>
                  <el-button type="text" icon="el-icon-delete" v-if="row.auditStatus==1&&row.frozenStatus==0" @click="audit(index,'0')">
                    反审
                  </el-button>
                  <el-button type="text" icon="el-icon-delete" v-if="row.frozenStatus==0&&row.auditStatus==1" @click="frozen(index,'1')">冻结
                  </el-button>
                  <el-button type="text" icon="el-icon-delete" v-if="row.frozenStatus==1&&row.auditStatus==1" @click="frozen(index,'0')">解冻
                  </el-button>
                  <el-button type="text" icon="el-icon-delete" v-if="row.auditStatus==0" class="red"
                             @click="removeEvent(index)">删除
                  </el-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Insert v-if="addVisible" :addVisible="addVisible" @closeInsert="closeInsert" @addSuccess="addSuccess"/>

    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delMethod">确 定</el-button>
            </span>
    </el-dialog>

    <el-dialog title="入库单" :visible.sync="itemVisible" width="1000px" >
      <div >
        <el-row>
          <el-col :span="11">
            <div>入库方式 :<span >{{stockTypeMap[editItem.otStockType]}}</span></div>
          </el-col>
          <el-col :span="4">
            <div >门店 :<span >{{editItem.storeName}}</span></div>
          </el-col>
          <el-col :span="6">
            <div > 日期 :<span >{{editItem.createTime}}</span> </div>
          </el-col>
          <el-col :span="3">
            <div >操作人 : <span >{{editItem.agent}}</span> </div>
          </el-col>
        </el-row>
      </div>
      <div  style="margin-top: 30px">
        <div class="e-card">
          <div class="e-card-body" style="padding-top:10px">
            <div class="e-table-wrapper">
              <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
                <thead>
                <tr>
                  <th width="130" class="th-l">商品编号</th>
                  <th width="130" class="th-l">商品名称</th>
                  <th width="130" class="th-l">规格</th>
                  <th width="130" class="th-l">单位</th>
                  <th width="130" class="th-l">供应商</th>
                  <th width="130" class="th-l">当前库存</th>
                  <th width="130" class="th-l">数量</th>
                  <th width="130" class="th-l">单价</th>
                  <th width="130" class="th-l">小计</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row,index) in itemList" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                  <td class="td-l"><span>{{row.skuNo}}</span></td>
                  <td class="td-l"><span>{{row.skuName}}</span></td>
                  <td class="td-l">{{row.skuSpecs}}</td>
                  <td class="td-l">{{unitTypeMap[row.unitType]}}</td>
                  <td class="td-l">{{row.supplierName}}</td>
                  <td class="td-l">
                    {{row.initInventory}}
                  </td>
                  <td class="td-l">
                    <el-input size="small" v-model="row.number" style="width: 80px"></el-input>
                  </td>
                  <td class="td-l">
                    {{row.unitPrice}}
                  </td>
                  <td class="td-l">
                    {{row.unitPrice*row.number}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import Insert from './insert'

  export default {
    data() {
      return {
        id: '',
        otNo: '',
        otStockType: '',
        auditStatus: '',

        list: [],
        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        addVisible: false,

        itemVisible: false,
        delVisible: false,
        editItem: {},


        cardTypeMap: {},
        cardTypeList: [],

        stockTypeMap: {
          '0': '产品销售',
          '1': '盘亏出库',
          '2': '套餐销售',
          '3': '采购退货',
          '4': '内部调出',
          '5': '损坏',
          '6': '赠送',
          '7': '员工领用',
          '8': '员工自购',
          '9': '产品开用',
          '10': '店内产品调出',
        },
        stockTypeList: [
          {name: '产品销售',value: '0'},
          {name: '盘亏出库',value: '1'},
          {name: '套餐销售',value: '2'},
          {name: '采购退货',value: '3'},
          {name: '内部调出',value: '4'},
          {name: '损坏',value: '5'},
          {name: '赠送',value: '6',},
          {name: '员工领用',value: '7'},
          {name: '员工自购',value: '8',},
          {name: '产品开用',value: '9',},
          {name: '店内产品调出',value: '10',}
        ],
        auditStatusList: [
          {name: '未审核',value: '0',},
          { name: '审核', value: '1',},
        ],
        itemList:[],
        unitTypeMap:{},
      }
    },
    async created() {
      this.searchEvent();
      this.getUnitTypeList()
    },
    methods: {
      async getUnitTypeList() {
        let res = await this.$get('/admin/getDictDetailList/UNIT_TYPE/' + Math.random());
        res.forEach(item => {
          this.unitTypeMap[item.dictValue] = item.name
        })
      },

      toItemList(index){
        this.editItem = this.list[index]
        this.itemVisible = true
        this.getItemList()
      },

      async getItemList(){
        let res = await this.$get("/admin/getOtItemList", {otStockId:this.editItem.id})
        this.itemList=res.itemList
        console.log(this.itemList)
      },

      async frozen(index, status) {
        let data = {
          id: this.list[index].id,
          frozenStatus: status
        }
        let res = await this.$post("/admin/otFrozen", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },
      async audit(index, status) {
        let data = {
          id: this.list[index].id,
          auditStatus: status
        }
        let res = await this.$post("/admin/otAudit", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      },
      handleCurrentChange(page) {
        this.requestSearch(page)
      },
      searchEvent() {
        this.requestSearch()
      },
      async requestSearch(page) {
        let currentPage = page || 1;
        let data = {
          otNo: this.otNo,
          otStockType: this.otStockType,
          auditStatus: this.auditStatus,
          isDel: 0,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }
        let res = await this.$get("/admin/getWmOtStockForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },

      addEvent() {
        this.addVisible = true;
      },

      closeInsert() {
        this.addVisible = false;
      },

      addSuccess() {
        this.closeInsert()
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },

      //删除
      async delMethod() {
        let data = {
          id: this.editItem.id
        };
        let res = await this.$post("/admin/delete_wmInStock", data)
        if (res.code == 200) {
          this.searchEvent();
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.delVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      },

    },
    components: {
      Insert,
    }
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
    .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
